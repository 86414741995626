<template>
  <div>
    <section class="product-list-heading pb-5">
      <div class="container-lg">
        <h2 class="pt-lg-5 pb-5 text-center">Frequently Asked Questions</h2>
        <form @submit.prevent="searchFaq(search)" class="form-search-static shadow">
          <input type="text" v-model="search" placeholder="Ketik kata kunci" />
          <button type="submit" class="btn btn-search"><fawesome-pro icon="search" variant="far" size="lg" /></button>
        </form>
        <div class="pt-5" v-if="filteredFaqs.length == 0">No entry in this page</div>
        <div class="pt-5" v-else v-for="(faq, i) in filteredFaqs" :key="i">
          <h2 class="roboto bold" style="font-size: larger;">{{faq.group_name}}</h2>
          <div class="faq-container">
            <div class="faq-collapse" v-for="(d, j) in faq.detail" :key="j">
              <div class="faq-collapse-title collapsed" :class="{'last' : j == faq.detail.length - 1}" @click="toggleBody">
                <p>{{d.faq_name}}</p>
              </div>
              <div class="faq-collapse-body" v-html="d.faq_content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";

export default {
  name: "FAQ",
  data() {
    return {
      faqs: [],
      filteredFaqs: [],
      search: null,
    }
  },
  computed: {
    resellerData() {
      return this.$store.getters.getResellerData
    },
  },
  mounted() {
    this.getFaqs(this.resellerData)
  },
  methods: {
    toggleBody(event) {
      event.currentTarget.classList.toggle("collapsed")
    },
    searchFaq(query) {
      if (query) {
        const faqs = JSON.parse(JSON.stringify(this.faqs))
        const filtered = faqs.filter((el) => {
          el.detail = el.detail.filter((rel) => rel.faq_name.toLowerCase().indexOf(query.toLowerCase()) > -1 || rel.faq_content.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return el.detail.length > 0
        })
        this.filteredFaqs = filtered.length > 0 ? filtered : this.faqs.filter((el) => el.group_name.toLowerCase().indexOf(query.toLowerCase()) > -1) 
      }
      else this.filteredFaqs = [...this.faqs]
    },
    // async getFaqs(isReseller) {
    async getFaqs() {
      try {
        // const res = await (!isReseller ? this.$api.cms.getFaqs() : this.$api.cms.getFaqsReseller())
        const res = await this.$api.cms.getFaqs()
        if (res.status === 200) this.faqs = this.filteredFaqs = res.data.data.faq_list
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
  }
}
</script>
<style scoped>
.form-search-static {
  display: flex;
  border: 1px solid #eee;
  border-radius: 0.5rem;
}
.form-search-static input {
  border: none;
  border-radius: .5rem;
  padding: 1rem 0 1rem 1rem;
  width: 100%;
}
.faq-container {
  border: 1px solid #eee;
  border-radius: .5rem;
  padding: 0 1rem;
  background: #fff;
}
.faq-collapse-title p {
  font-weight: bold;
  color: #d89700;
  max-width: calc(100% - 15px - .5rem);
  margin: 0;
}
.faq-collapse-title {
  cursor: pointer;
  position: relative;
  padding: .5rem 0;
  border-bottom: 1px solid #eee;
}
.faq-collapse-title:after {
  content: "\f077";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  transition: transform .2s ease-out;
}
.faq-collapse-title.collapsed:after {
  transform: rotate(180deg);
}
.faq-collapse-title.collapsed > p {
  font-weight: normal;
  color: black;
}
.faq-collapse-body {
  max-height: 428px;
  overflow: auto;
  visibility: visible;
  transition: max-height .4s ease-out;
  border-bottom: 1px solid #eee;
}
.faq-collapse-title.collapsed + .faq-collapse-body {
  max-height: 0;
  visibility: collapse;
}
.faq-collapse-body >>> p {
  font-size: .9rem;
  color: #6e6d71;
  padding: .5rem 0;
  margin: 0;
}
.faq-collapse-title.last {
  border-bottom: none;
}
.faq-collapse-title.last + .faq-collapse-body {
  border-top: 1px solid #eee;
  border-bottom: none;
}
</style>
